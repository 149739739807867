import React from "react"
import PropTypes from "prop-types"

import Seo from "../components/seo"
import Nav from "./nav"
import Footer from "./footer"

import "../assets/css/main.css"

const Layout = ({ children, title }) => {
  const optionalTitle = title ? title : false
  return (
    <>
    <Seo optionalTitle={optionalTitle}/>
      <div id="page-container">
        <div id="page-content">
          <Nav id="nav" />
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout