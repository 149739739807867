import React from "react"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
library.add( faArrowUp )

const Footer = () => (
  <footer>
    <div id="footer" className="uk-section">
      <div className="uk-container uk-container-small">
        <div className="uk-navbar-container" data-uk-navbar>
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav">
              <li>
                <div className="footer-build-info">
                  Built with <a href="https://www.gatsbyjs.org/" title="Gatsby JS">GatsbyJS</a> &amp; <a href="https://strapi.io/" title="Strapi">Strapi</a>
                </div>
              </li>
            </ul>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              <li className="nav-icon">
                <a href="#top" title="Scroll To Top">
                  <Icon icon="arrow-up" size="lg"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer