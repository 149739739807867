import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

import { fab } from "@fortawesome/free-brands-svg-icons"
import { faHome, faUserEdit, faTh } from "@fortawesome/free-solid-svg-icons"
library.add( fab, faHome, faUserEdit, faTh )

const Nav = () => (
  <header>
    <div id="nav">
      <a name="top" href="#"></a>
      <div className="uk-container uk-container-small">
        <nav className="uk-navbar-container" data-uk-navbar>
          <StaticQuery
            query={graphql`
            {
              strapiFooter {
                id
                social_links {
                  hyperlink
                  text
                  icon {
                    publicURL
                  }
                }
              }
              allStrapiCategory {
                edges {
                  node {
                    strapiId
                    name
                    slug
                  }
                }
              }
            }
            `}
            render={data =>
              (
                <>
                <div className="uk-navbar-left">
                  <ul className="uk-navbar-nav">
                    <li className="nav-icon">
                      <Link to="/" title="Home">
                        <Icon icon="home" size="lg"/>
                      </Link>
                    </li>
                    <li className="nav-icon">
                      <Link to="/about" title="About">
                        <Icon icon="user-edit" size="lg"/>
                      </Link>
                    </li>
                    <li className="nav-icon">
                      <Link to="/categories" title="Posts by Categories">
                        <Icon icon="th" size="lg"/>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="uk-navbar-right">
                  <ul className="uk-navbar-nav">
                  {/* Dynamic Social */}
                  {data.strapiFooter.social_links.map((social_link, i) => (
                    <li key={social_link.text} className="nav-icon">
                      <a href={social_link.hyperlink} title={social_link.text} target="_blank">    
                        <Icon icon={['fab', `${social_link.text.toLowerCase()}`]} size="lg"/>
                      </a>
                    </li>
                  ))
                  }
                  </ul>
                </div>
                </>
              )}
          />            
        </nav>
      </div>
    </div>
  </header>
)

export default Nav